export const details = {
	goBack: "BACK",
	edit: "Edit",
	confirm: "Are you sure you want to change this customer's information?",
	clientDetails: "Customer data",
	consummationGiven: "Consummation given",
	rechargesReturned: "Recharges returned",
	singleProductReturns: "Product returns",
	paymentMethods: {
		app: "Application",
		cash: "Cash",
		creditCard: "Credit",
		debitCard: "Debit",
		zigPosCredit: "Zig post credit",
		zigPosDebit: "Zig post debit",
		voucher: "Voucher",
		dashboard: "Dashboard",
		anticipated: "Anticipated",
		postEvent: "Post event",
		ame: "Ame",
		deliveryOnline: "Delivery online",
		ifood: "Ifood",
		uber: "Uber",
		rappi: "Rappi",
		total: "Total",
		pix: "Pix",
		installment: "Installment",
		foodVoucher: "Food Voucher",
		noMethod: "Uninformed",
		online: "Online",
		mbway: "Mbway",
	},
	data: {
		cpf: "CPF",
		email: "Email",
		noEmail: "No registered email",
		phone: "Telephone",
		noPhone: "No registered phone",
		birthDate: "Birth date",
		notRegistered: "Date not registered",
		remaningValue: "Balance available on site",
		hasApp: "Has Application",
		checkIn: "Checkin",
		checkOut: "Checkout",
		noRegistry: "No registry",
		notDefined: "Did not occur",
		at: "at",
		showMore: "show more information",
		showLess: "show less",
		clientsAtTag: "Number of customers at table",
	},
	tabs: {
		bonusAndDiscounts: "Bonus and Discounts",
		debtorsReport: "Opened Bills",
		ticketsByUserReport: "Token control",
	},
	sheet: {
		fileName: "consolidated-history",
		withoutInfo: "No information to display",
		summary: {
			title: "Summary",
			recharges: "Recharges",
			rechargesFromOtherEvents: "Recharges from other events",
			notSyncRecharges: "Recharges not synced",
			notUsedRecharges: "Unused recharges",
			rechargeDevolutions: "Return of recharge",
			rechargeBalance: "Recharge balance",
			bonusReceveid: "Received bonus",
			entrances: "Entrances",
			products: "Products",
			service: "Service",
			totalConsumption: "Total Consumption",
			payments: "Payments",
			paymentsInOtherEvents: "Payments at other events",
			openedBill: "Opened Bills",
			minimumConsumptionUsed: "Consumption used",
			notUsedMinimumConsumption: "Unused consumption",
			columns: {
				method: "Type",
				value: "Value",
			},
			bonusPayments: "Payment with remaining bonus",
		},
		consumption: {
			title: "Consumption",
			columns: {
				name: "Name",
				count: "Quantity",
				value: "Value",
			},
		},
		recharges: {
			title: "Recharges",
			columns: {
				method: "Method",
				value: "Value",
			},
		},
		payments: {
			title: "Payments",
			columns: {
				method: "Type",
				value: "Value",
			},
		},
		actions: {
			title: "Actions",
			columns: {
				method: "Type",
				value: "Value",
			},
		},
		transactionalHistory: {
			columns: {
				transaction: "transaction",
				seller: "seller",
				value: "value",
				id: "id",
				time: "transaction time",
				sync: "synchronization",
				zigCode: "zigTag",
				bonusCategoryName: "bonus category",
			},
		},
		consumptionHistory: {
			title: "Consumption History",
			consumption: "Consumption",
			servicePaidOnEvent: "Service Paid at Event",
			amountConsumption: "Total Consumption",
			columns: {
				date: "Date",
				type: "Type",
				item: "Item",
				qtd: "Qty",
				value: "Value",
				discountValue: "Discount",
			},
		},
	},
	downloadTable: {
		fileName: "consolidated-history",
		recharges: {
			title: "Recharges",
			columns: {
				date: "Date",
				rechargesInEvent: "Recharges at the event",
				rechargesFromOtherEvents: "Recharges from other events",
				notSynchRecharges: "Recharges not synced",
				notUsedRecharges: "Unused recharges",
				rechargeDevolutions: "Return of Recharges",
			},
		},
		consumption: {
			title: "Consumption",
			columns: {
				date: "Date",
				entrances: "Entrances",
				products: "Products",
				service: "Service",
				totalConsumption: "Total Consumption",
			},
		},
		payments: {
			title: "Payments",
			columns: {
				date: "Date",
				paymentsInEvent: "Payments at the event",
				paymentsInOtherEvents: "Payments for other events",
				openedBill: "Opened Bills",
				bonusPayments: "Payment with remaining bonus",
			},
		},
		bonusAndConsumption: {
			title: "Bonus and Consumption",
			columns: {
				date: "Date",
				bonusReceived: "Bonus received",
				consumptionUsed: "Consumption used",
				notUsedConsumption: "Unused consumption",
			},
		},
		actions: {
			title: "Actions",
			columns: {
				date: "Date",
				removedTips: "Tips removed",
				postPaidLimitChanged: "Postpaid limit change",
				attachCount: "Attached zig cards",
				packages: "Packages",
				productRefunds: "Reversed products",
				promotionsUsed: "Promotions used",
			},
		},
	},
	consolidatedHistory: {
		label: "Consolidated History",
		printInfo: "Print Information",
		recharges: "Recharges",
		totalValue: "Value",
		consumption: "Consumption",
		bonusAndConsumption: "Consumption/Bonus",
		payments: "Payments",
		action: "Action",
		quantity: "Quantity",
		method: "Method",
		unknown: "Unknown",
		balance: "Balance",
		consumptionObligations: "Minimum consumption value",
		consumptionObligationsNotUsed: "Leftover",
		product: "Product",
		qntt: "Qntt.",
		value: "Value",
		discount: "Discount",
		isShared: "-(shared)",
		totalConsumption: "Total consumption",
		others: "Others",
		name: "Name",
		formatters: {
			rechargesFromOtherEvents: "Recharges from other events",
			rechargeDevolutions: "Recharges returned",
			rechargeDevolutionsFromOtherEvents: "Recharges returned from other events",
			notSyncRecharges: "Recharges not synced",
			bonusReceived: "Bonus received",
			minimumConsumptionUsed: "Consumption used",
			notUsedMinimumConsumption: "Unused consumption",
			tip: "Service",
			openedBillPayments: "Payment of open accounts",
			paymentsInEvent: "Payments at the event",
			paymentsInOtherEvents: "Payments at other events",
			openedBill: "Opened Bills",
			notUsedRecharges: "Unused recharges",
			rechargesInEvent: "Recharges at the event",
			entrances: "Entrances",
			products: "Products",
			consumptionObligations: "Minimum consumptions",
			consumptionObligationsNotUsed: "Minimum consumption leftovers",
			productRefunds: "Reversed products",
			packages: "Packages",
			promotionsUsed: "Promotions used",
			removedTip: "Tips removed",
			postPaidLimitChanged: "Postpaid limit change",
			attachCount: "Zig cards attached",
			bonusPayments: "Payment with remaining bonus",
		},
	},
	transactionalHistory: {
		label: "Transactional History",
		export: "Export to Excel",
		formatters: {
			attach: "Card registration",
			attachToReserve: "Inclusion in the package",
			bonus: "Bonus",
			changePostLimit: "Limit change",
			consumeRecharge: "Recharge return",
			detachAndRefundActivation: "Card return",
			discount: "Discount",
			entranceTransaction: "Entrance",
			minimumConsumption: "Consumption",
			postPayment: "Payment",
			productTransaction: "Purchase",
			promotion: "Won promotion",
			recharge: "Recharge",
			refundRecharge: "Recharge chargeback",
			refundTransaction: "Refund",
			sharedTransaction: "Shared payment",
			tipRemoval: "Service removed",
			appPrePayment: "Prepayment via app",
			consumptionObligation: "Minimum consumption",
			zigDeviceSyncForce: "Balance forced sync",
			transferProductTransaction: "Transfer product",
			zigCardPixRefund: "Pix refund",
			transferConsumptionObligation: "Transfer consumption obligation",
		},
		employee: "Seller",
		value: "Value",
		id: "ID",
		hour: "Time",
		sheet: {
			fileName: "transactional-history-of-",
			in: "-in-",
			title: "Transactional History of {{user}} in {{event}}",
			id: "ID",
			operation: "Operation",
			employee: "Seller",
			value: "Value",
			date: "Date",
			hour: "Time",
			bonusCategoryName: "bonus category",
		},
		event: "Event",
		paymentTable: {
			method: "Method",
			value: "Value",
			name: "Payers",
			cpf: "CPF",
			acquirer: "Acquirer",
		},
		productTable: {
			name: "Product",
			details: "Details",
			value: "Price",
			count: "Qty.",
			discountValue: "Discount",
			totalValue: "Final value",
			total: "TOTAL",
			transferFrom: "Transferred from ",
			transferTo: "Transferred to ",
			item: "Item",
			section: "Section",
		},
		refundTable: {
			name: "Product",
			count: "Qty.",
		},
		service: "Service",
		unknown: "Unknown",
		loading: "Loading...",
		acquirerModal: {
			unknown: "Unknown",
			acquirer: "Acquirer",
			urlLabel: "url",
			cardHolderNumber: "Card",
			cardHolderName: "Cardholder Name",
			transactionType: "Transaction type",
			notKnown: "Not known",
			paidAmount: "Transaction value",
		},
	},
	fiscalNotes: {
		label: "Invoices",
		send: "Send invoices by email",
		emit: "Issue invoice",
		mode: {
			serviceNFS: "Service (NFS-e)",
			consumptionNFC: "Consumption (NFC-e)",
			consumptionSAT: "Consumption (SAT)",
		},
		columns: {
			time: "Date/Time",
			type: "Type",
			status: "Status",
			links: "Links",
		},
		invoiceStatus: {
			canceled: "Invoice canceled",
			issued: "Invoice issued",
			issuing: "Issuing invoice",
			error: "Error when issuing",
		},
		showError: "View error",
		image: "Image",
		generate: "To generate {{type}}",
		at: "at",
		empty: "No customer invoices for the selected period.",
		modalError: {
			title: "Error description",
			content: "Contact support and file this error",
			error: "Error",
		},
		cancelInvoice: {
			title: "Are you sure you want to cancel the invoice?",
			okText: "Yes, cancel invoice",
		},
	},
	ticketByUserReport: {
		excel: {
			title: "Token control - {{userName}}",
			filename: "token_control_{{userName}}",
		},
		columns: {
			product: "Product",
			burnedTickets: "Burned Tokens",
			refundedTickets: "Refunded Tokens",
			purchasedTickets: "Purchased Tokens",
		},
		emptyState: {
			title: "No tokens consumed",
			description: "User has not purchased any tokens yet",
		},
	},
	bonusAndDiscounts: {
		discounts: {
			label: "Discounts",
			columns: {
				date: "Date",
				givenBy: "Given by",
				reason: "Reason",
				percentage: "Percentage",
				value: "Discount",
			},
		},
		bonus: {
			label: "Bonus",
			columns: {
				date: "Date",
				givenBy: "Given by",
				reason: "Reason",
				totalValue: "Amount received",
				usedValue: "Amount spent",
			},
		},
		sheet: {
			fileName: "discounts-and-bonus",
			discount: {
				clientTitle: "Discounts of {{client}}",
				title: "Discounts",
				columns: {
					date: "Date",
					givenBy: "Given by",
					reason: "Reason",
					percentage: "Percentage",
					value: "Discount",
				},
			},
			bonus: {
				clientTitle: "Bonus of {{client}}",
				title: "Bonus",
				columns: {
					date: "Date",
					givenBy: "Given by",
					reason: "Reason",
					totalValue: "Amount received",
					usedValue: "Amopunt spent",
				},
			},
		},
	},
	issueNotes: "Issuing invoices",
	invoices: {
		alert:
			"If you want to group invoices from other customers to those already selected, just add them below",
		generateServiceFee: "Generate proportional service fee",
		cpfPlaceholder: "Enter a CPF",
		nifPlaceholder: "Enter a NIF",
		client: "Client",
		valueToEmit: "Value to emit",
		totalNotissued: "Total not issued",
		otherCPF: "Issue to another CPF",
		otherCNPJ: "Issue to another CNPJ",
		otherNIF: "Issue to another NIF",
		noCPForCNPJ: "Issuing without CPF and without CNPJ",
		noNIF: "Issuing without NIF",
		issueTo: "Issue to",
		cpf: "CPF",
		nif: "NIF",
		cnpj: "CNPJ",
		cnpjPlaceholder: "Enter a CNPJ",
		empty: "There are no transactions to be issued.",
		products: "Products",
		count: "Quantity",
		discountValue: "Discount",
		value: "Value",
		id: "Transaction",
		date: "Time and Date",
		unitValue: "Total Value",
		steps: {
			buyers: "Buyers",
			transactions: "Transactions not issued",
			consumption: "Minimum consumption not issued",
			emission: "Emission",
		},
	},
	printable: {
		bonus: "Bonus",
		recharge: "Recharge",
		date: "Date",
		author: "Author",
		total: "Total",
		expired: "Expired",
		used: "Used",
		method: "Method",
		refunded: "Refunded",
		purchase: "Purchase",
		shared: "Shared",
		service: "Service",
		products: "Products",
		name: "Name",
		count: "Quantity",
		discount: "Discount",
		totalProducts: "Total ({{count}} Products)",
		paidBill: "Paid bill",
		cashier: "Cashier",
		payments: "Payments",
		paymentMethod: "Payment Method",
		payers: "Payers",
		value: "Value",
		cpf: "cpf",
		email: "Email",
		phone: "Phone",
		zigcode: "Zigcode",
		unavailable: "Unavailable",
	},
	sendByEmail: {
		nfce: "Consumption (NFC-e)",
		nfse: "Service (NFS-e)",
		sat: "Consumption (SAT)",
		sendEmail: "Send email",
		datetime: "Date/Time",
		type: "Type",
		sendInvoucesByEmail: "Send invoices by email",
		invoices: "Invoices",
		email: "Email",
	},
	checkOutMethod: {
		biometry: "Biometry",
		pdv: "PDV",
		app: "App",
		automatic: "Automatic",
		menu: "Menu",
	},
};
